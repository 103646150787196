import { OrbitControls } from "@react-three/drei";
import Level from "./Level.jsx";
import Lights from "./Lights.jsx";
import { Debug, Physics } from "@react-three/rapier";
import Player from "./Player.jsx";
import useGame from "./stores/useGame.js";
import Effects from "./Effects.jsx";
export default function Experience() {
  const blocksCount = useGame((state) => state.blocksCount);
  return (
    <>
      {/* <OrbitControls makeDefault /> */}
      <color args={["#252731"]} attach="background" />
      <Lights />
      <Physics>
        {/* <Debug /> */}
        <Level count={blocksCount} />
        <Player />
      </Physics>
      <Effects />
    </>
  );
}
