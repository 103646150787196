import * as THREE from "three";
import { RigidBody, CuboidCollider } from "@react-three/rapier";
import { useFrame } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Float, useGLTF, Text } from "@react-three/drei";
import useGame from "./stores/useGame";

THREE.ColorManagement.legacyMode = false;

const boxGeometry = new THREE.BoxGeometry(1, 1, 1);

const especialFloorMaterial = new THREE.MeshStandardMaterial({
  color: [2, 1, 1],
  metalness: 0,
  roughness: 0,
  toneMapped: false,
});
const obstacleFloorMaterial = new THREE.MeshStandardMaterial({
  metalness: 0,
  roughness: 0,
  toneMapped: false,
});
const obstacleMaterial = new THREE.MeshStandardMaterial({
  color: "#ff0000",
  metalness: 0,
  roughness: 1,
});
const wallMaterial = new THREE.MeshStandardMaterial({
  color: "#887777",
  metalness: 0,
  roughness: 0,
});

function BlockStart({ position = [0, 0, 0] }) {
  return (
    <group position={position}>
      <mesh
        position={[0, -0.05, 0]}
        scale={[4, 0.3, 4]}
        geometry={boxGeometry}
        // material={especialFloorMaterial}
        receiveShadow
      >
        <meshStandardMaterial
          toneMapped={false}
          color={[1.6082, 1.2628, 0.0643]}
        />
      </mesh>
    </group>
  );
}

function BlockEnd({ position = [0, 0, 0] }) {
  const model = useGLTF("./hamburger.glb");
  model.scene.children.forEach((mesh) => {
    mesh.castShadow = true;
  });
  return (
    <group position={position}>
      <Text
        font="/bebas-neue-v9-latin-regular.woff"
        scale={0.5}
        position={[0, 2.25, 2]}
      >
        Finish
        <meshBasicMaterial toneMapped={false} />
      </Text>
      <mesh
        position={[0, -0.05, 0]}
        scale={[4, 0.3, 4]}
        geometry={boxGeometry}
        // material={especialFloorMaterial}
        receiveShadow
      >
        <meshStandardMaterial
          toneMapped={false}
          color={[1.6082, 1.2628, 0.0643]}
        />
      </mesh>
      <RigidBody
        type="fixed"
        colliders="hull"
        restitution={0.2}
        friction={0}
        position={[0, 0.25, 0]}
        // Para agregarle sombra se hace desde el useGLTF
      >
        <primitive object={model.scene} scale={0.2} />
      </RigidBody>
    </group>
  );
}

export function BlockTrapSpinner({ position = [0, 0, 0] }) {
  const obstacleRef = useRef();

  const [obstacleSpeed] = useState(
    () => Math.random() + 0.2 * (Math.random() < 0.5 ? -1 : 1)
  );

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const rotation = new THREE.Quaternion();
    rotation.setFromEuler(new THREE.Euler(0, time * obstacleSpeed, 0));
    obstacleRef.current.setNextKinematicRotation(rotation);
  });
  return (
    <group position={position}>
      <mesh
        position={[0, -0.1, 0]}
        scale={[4, 0.2, 4]}
        geometry={boxGeometry}
        // material={obstacleFloorMaterial}
        receiveShadow
      >
        <meshStandardMaterial
          color={"#222222"}
          toneMapped={false}
          roughness={0}
          metalness={0}
        />
      </mesh>
      <RigidBody
        type="kinematicPosition"
        position={[0, 0.3, 0]}
        restitution={0.2}
        friction={0}
        ref={obstacleRef}
      >
        <mesh
          scale={[3.5, 0.3, 0.3]}
          geometry={boxGeometry}
          // material={obstacleMaterial}
          castShadow
          receiveShadow
        >
          <meshStandardMaterial color={[3, 0, 0]} toneMapped={false} />
        </mesh>
      </RigidBody>
    </group>
  );
}

export function BlockLimboTrap({ position = [0, 0, 0] }) {
  const obstacleRef = useRef();

  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    obstacleRef.current.setNextKinematicTranslation({
      x: position[0],
      y: Math.sin(time * timeOffset) + 1.175,
      z: position[2],
    });
  });
  return (
    <group position={position}>
      <mesh
        position={[0, -0.1, 0]}
        scale={[4, 0.2, 4]}
        geometry={boxGeometry}
        // material={obstacleFloorMaterial}
        receiveShadow
      >
        <meshStandardMaterial
          color={"#222222"}
          toneMapped={false}
          roughness={0}
          metalness={0}
        />
      </mesh>
      <RigidBody
        type="kinematicPosition"
        position={[0, 0.3, 0]}
        restitution={0.2}
        friction={0}
        ref={obstacleRef}
      >
        <mesh
          scale={[3.5, 0.3, 0.3]}
          geometry={boxGeometry}
          // material={obstacleMaterial}
          castShadow
          receiveShadow
        >
          <meshStandardMaterial color={[3, 0, 0]} toneMapped={false} />
        </mesh>
      </RigidBody>
    </group>
  );
}

export function BlockAxeTrap({ position = [0, 0, 0] }) {
  const obstacleRef = useRef();

  const [timeOffset] = useState(() => Math.random() * Math.PI * 1.25);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    obstacleRef.current.setNextKinematicTranslation({
      x: Math.sin(time * timeOffset),
      y: position[1] + 0.95,
      z: position[2],
    });
  });
  return (
    <group position={position}>
      <mesh
        position={[0, -0.1, 0]}
        scale={[4, 0.2, 4]}
        geometry={boxGeometry}
        // material={obstacleFloorMaterial}
        receiveShadow
      >
        <meshStandardMaterial
          color={"#222222"}
          toneMapped={false}
          roughness={0}
          metalness={0}
        />
      </mesh>

      <RigidBody
        type="kinematicPosition"
        position={[0, 0.3, 0]}
        restitution={0.2}
        friction={0}
        ref={obstacleRef}
      >
        <mesh
          scale={[1.5, 1.5, 0.3]}
          geometry={boxGeometry}
          // material={obstacleMaterial}
          castShadow
          receiveShadow
        >
          <meshStandardMaterial color={[3, 0, 0]} toneMapped={false} />
        </mesh>
      </RigidBody>
    </group>
  );
}

function Walls({ lenght = 1 }) {
  return (
    <>
      <RigidBody type="fixed" restitution={0.2} friction={0}>
        <mesh
          geometry={boxGeometry}
          material={wallMaterial}
          position={[2.15, 0.75, -(lenght * 2) + 2]}
          scale={[0.3, 1.5, 4 * lenght]}
          castShadow
        />
        <mesh
          geometry={boxGeometry}
          material={wallMaterial}
          position={[-2.15, 0.75, -(lenght * 2) + 2]}
          scale={[0.3, 1.5, 4 * lenght]}
          receiveShadow
        />
        <mesh
          geometry={boxGeometry}
          material={wallMaterial}
          position={[0, 0.75, -(lenght * 4) + 2]}
          scale={[4, 1.5, 0.3]}
          castShadow
          receiveShadow
        />
        {/* <mesh
          geometry={boxGeometry}
          material={wallMaterial}
          position={[0, 0.75, 2]}
          scale={[4, 1.5, 0.3]}
          castShadow
          receiveShadow
        /> */}

        {/* Collider para el suelo */}
        <CuboidCollider
          args={[2, 0.1, 2 * lenght]}
          position={[0, -0.1, -(lenght * 2) + 2]}
          restitution={0.2}
          // Ocupamos friccion para ver la bola rotar
          friction={1}
        />
      </RigidBody>
    </>
  );
}

export default function Level({
  count = 5,
  types = [BlockTrapSpinner, BlockLimboTrap, BlockAxeTrap],
}) {
  const blockSeed = useGame((state) => state.blockSeed);
  const blocks = useMemo(() => {
    const blocks = [];
    for (let i = 0; i < count; i++) {
      const type = types[Math.floor([Math.random() * types.length])];
      blocks.push(type);
    }

    return blocks;
  }, [count, types, blockSeed]);
  return (
    <>
      <BlockStart position={[0, 0, 0]} />
      {blocks.map((Block, index) => {
        return <Block key={index} position={[0, 0, (index + 1) * -4]} />;
      })}
      <BlockEnd position={[0, 0, (count + 1) * -4]} />

      <Walls lenght={count + 2} />

      <Float floatIntensity={0.25} rotationIntensity={0.25}>
        <Text
          maxWidth={0.25}
          lineHeight={0.75}
          textAlign="right"
          position={[0.75, 0.65, 0]}
          rotation-y={-0.25}
          font="/bebas-neue-v9-latin-regular.woff"
          scale={0.5}
        >
          Marble Race
          <meshBasicMaterial toneMapped={false} />
        </Text>
      </Float>
    </>
  );
}
